/**
 * @Auther    william
 * @Name      state get function
 * @Note      
 * @Time      2021/04/13 16:07
 */

let getters = {
    // get all state message
    getAll(state) {
        return state
    },
    // get axios token
    getToken(state) {
        return state.token
    },
    // get logo
    getLogo(state) {
        return state.logo
    },
    // get dsKey
    getDsKey(state) {
        return state.dsKey
    },
    // get language
    getLanguage(state) {
        return state.language
    },
    // get copy right message
    getCopyRight(state) {
        return state.copyRight
    },
    // get noImage
    getNoImage(state) {
        return state.noImage
    },
    // get some user message
    getUserMsg(state) {
        return {
            username: state.username,
            idRol: state.idRol,
            userType: state.userType,
            tenantDb: state.tenantDb,
            portrait: state.portrait,
            lastIp: state.lastIp,
            lastTime: state.lastTime,
        }
    },
    // get theme msg
    getTheme(state) {
        return state.theme
    },
    getType(state) {
        return state.type
    },
    getPermissionList(state) {
        return state.permissionList
    },
}

export default getters