<template>
  <Modal
      id="FormModel"
      width="50%"
      :visible="visible"
      :confirmLoading="confirmLoading"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('pileManage.field.id')" name="id">
                    <Input
                        :disabled="true"
                        :placeholder="$t('pileManage.validate.id')"
                        v-model:value="form.id">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('pileManage.field.identifier')" name="identifier">
                    <Input
                        :disabled="actionType==='edit'"
                        :placeholder="$t('pileManage.validate.identifier')"
                        v-model:value="form.identifier">
                    </Input>
                  </FormItem>
                </Col>
              
                <Col span="10">
                  <FormItem :label="$t('pileManage.field.reId')" name="reId">
                    <Select
                        v-model:value="form.reId"
                        show-search
                        :placeholder="$t('pileManage.validate.reId')"
                        style="width: 300px;"
                        :options="options"
                        :filter-option="filterOption"
                        @change="handleChange"
                    ></Select>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('pileManage.field.evName')" name="evName">
                    <Input :placeholder="$t('pileManage.validate.evName')" v-model:value="form.evName"></Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('pileManage.field.readMeter')" name="readMeter">
                    <Select v-model:value="form.readMeter" :placeholder="$t('public.placeholder.please_select')">
                      <SelectOption :value="'0'"> {{$t('pileManage.field.readMeterList.0')}} </SelectOption>
                      <SelectOption :value="'1'"> {{$t('pileManage.field.readMeterList.1')}} </SelectOption>
                    </Select>
                  </FormItem>
                </Col>

                <Col span="10"></Col>

<!--                <Col span="10">-->
<!--                  <FormItem :label="$t('pileManage.field.evType')" name="evType">-->
<!--                    <Select-->
<!--                        v-model:value="form.evType"-->
<!--                        :placeholder="$t('pileManage.validate.evType')"-->
<!--                        :options="chargingPortTypeList"-->
<!--                        :dropdownStyle="{ zIndex: 9999999950 }"-->
<!--                    />-->
<!--                  </FormItem>-->
<!--                </Col>-->
              
                <Col span="22">
                  <FormItem :label="$t('pileManage.field.remark')" name="remark">
                    <Textarea :placeholder="$t('pileManage.validate.remark')"
                              v-model:value="form.remark">
                    </Textarea>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";

import {PileAddInfo, PileEditInfo} from "@/api/pile";
import {RentalGetTenantList} from "@/api/rental";

export default {
  name: "FormModel",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea: Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
  },
  props: ["options"],
  data() {
    return {
      chargingPortTypeList:[
        {value: '0', label: 'Type 1'},
        {value: '1', label: 'Type 2'},
        {value: '2', label: 'CHAdeMO'},
        {value: '3', label: 'CCS Combo Type 1'},
        {value: '4', label: 'CCS Combo Type 2'},
      ],
      confirmLoading: false,
      visible: false,
      actionType: "",
      form: {
        id: undefined,
        identifier: undefined,
        reId: undefined,
        evName: undefined,
        evStatus: undefined,
        evType: undefined,
        evArgs: undefined,
        remark: undefined,
      },
      fileList: {},
      validateRules: {
        id: [
          {
            required: false,
            message: this.$t("pileManage.validate.id"),
            trigger: "change",
          },
        ],
        identifier: [
          {
            required: true,
            message: this.$t("pileManage.validate.identifier"),
            trigger: "change",
          },
        ],
        reId: [
          {
            required: true,
            message: this.$t("pileManage.validate.reId"),
            trigger: "change",
          },
        ],
        evName: [
          {
            required: true,
            message: this.$t("pileManage.validate.evName"),
            trigger: "change",
          },
        ],
        evStatus: [
          {
            required: true,
            message: this.$t("pileManage.validate.evStatus"),
            trigger: "change",
          },
        ],
        evType: [
          {
            required: true,
            message: this.$t("pileManage.validate.evType"),
            trigger: "change",
          },
        ],
        evArgs: [
          {
            required: true,
            message: this.$t("pileManage.validate.evArgs"),
            trigger: "change",
          },
        ],
        remark: [
          {
            required: true,
            message: this.$t("pileManage.validate.remark"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  watch: {
    "visible": {
      deep: true,
      handler: function (newValue, oldValue) {
        if (!newValue) {
          this.$emit("updateList");
        }
      }
    }
  },
  methods: {
    addShow() {
      // this.getSelectOptions()
      this.actionType = "add";
      this._resetData();
      this.visible = true;
    },
    async editShow(obj) {
      // this.getSelectOptions()
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
    submit() {
      this.$refs["refForm"].validate().then(() => {
        this.confirmLoading = true
        let api;
        switch (this.actionType) {
          case "add":
            console.log("add");
            api = new PileAddInfo();
            break;
          case "edit":
            console.log("edit");
            api = new PileEditInfo();
            break;
          default:
            return false;
        }
        // 取的是后台服务器时间，前端需要过滤掉时间(或者格式化时间)，否则后端校验异常
        delete this.form.createTime
        delete this.form.updateTime
        // 构建参数
        const param = {
          ...this.form,
        }
        api.post(param).then((response, any) => {
          this.confirmLoading = false
          if (0 === parseInt(response.data.code)) {
            message.success(response.data.msg);
            // 触发主页面组件定义事件
            this.$emit("updateList", response.data.data);
            this.visible = false;
          } else {
            message.error(response.data.msg);
          }
        });
      })
          .catch(() => {
          });
    },

    // 搜索框过滤筛选
    filterOption(input, option) {
      return option.label.indexOf(input) >= 0;
    },
    // 搜索框选择事件
    handleChange(value) {},

    // reset data
    _resetData() {
      this.form = {
        id: undefined,
        identifier: undefined,
        reId: undefined,
        evName: undefined,
        evStatus: undefined,
        evType: undefined,
        evArgs: undefined,
        remark: undefined,
      };
    },
  },
};
</script>

<style lang="less">
@import url("style");
</style>
