<template>
  <Modal
      id="FormModel"
      width="50%"
      :visible="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" >
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('rentalManage.field.id')" name="id">
                    <Input
                        :disabled="true"
                        :placeholder="$t('rentalManage.validate.required.id')"
                        v-model:value="form.id">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('rentalManage.field.reId')" name="reId">
                    <Input :placeholder="$t('rentalManage.validate.required.reId')"
                           v-model:value="form.reId">
                    </Input>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('rentalManage.field.reName')" name="reName">
                    <Input :placeholder="$t('rentalManage.validate.required.reName')"
                           v-model:value="form.reName">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('rentalManage.field.reAisle')" name="reAisle">
                    <Input :placeholder="$t('rentalManage.validate.required.reAisle')"
                           v-model:value="form.reAisle">
                    </Input>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('rentalManage.field.reStatus')" name="reStatus">
                    <Input :placeholder="$t('rentalManage.validate.required.reStatus')"
                           v-model:value="form.reStatus">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('rentalManage.field.reArgs')" name="reArgs">
                    <Input :placeholder="$t('rentalManage.validate.required.reArgs')"
                           v-model:value="form.reArgs">
                    </Input>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('rentalManage.field.reIsAuthorized')" name="reIsAuthorized">
                    <Input :placeholder="$t('rentalManage.validate.required.reIsAuthorized')"
                           v-model:value="form.reIsAuthorized">
                    </Input>
                  </FormItem>
                </Col>
              </Row>

              <Row justify="space-around">
                <Col span="22">
                  <FormItem :label="$t('rentalManage.field.remark')" name="remark">
                    <Textarea :placeholder="$t('rentalManage.validate.required.remark')"
                              v-model:value="form.remark">
                    </Textarea>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";

import {RentalAddInfo, RentalEditInfo} from "@/api/rental";
import moment from "moment";

export default {
  name: "FormModel",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea: Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
  },
  data() {
    return {

      visible: false,
      actionType: "",
      form: {
        id: undefined,
        reId: undefined,
        reName: undefined,
        reAisle: undefined,
        reStatus: undefined,
        reArgs: undefined,
        reIsAuthorized: undefined,
        reLeaseTime: undefined,
        remark: undefined,
      },
      fileList: {},
      validateRules: {
        id: [
          {
            required: false,
            message: this.$t("rentalManage.validate.required.id"),
            trigger: "change",
          },
        ],
        reId: [
          {
            required: true,
            message: this.$t("rentalManage.validate.required.reId"),
            trigger: "change",
          },
        ],
        reName: [
          {
            required: true,
            message: this.$t("rentalManage.validate.required.reName"),
            trigger: "change",
          },
        ],
        reAisle: [
          {
            required: true,
            message: this.$t("rentalManage.validate.required.reAisle"),
            trigger: "change",
          },
        ],
        reStatus: [
          {
            required: true,
            message: this.$t("rentalManage.validate.required.reStatus"),
            trigger: "change",
          },
        ],
        reIsAuthorized: [
          {
            required: true,
            message: this.$t("rentalManage.validate.required.reIsAuthorized"),
            trigger: "change",
          },
        ],
        reLeaseTime: [
          {
            required: true,
            message: this.$t("rentalManage.validate.required.reLeaseTime"),
            trigger: "change",
          },
        ],
        remark: [
          {
            required: true,
            message: this.$t("rentalManage.validate.required.remark"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  watch: {
    "visible": {
      deep: true,
      handler: function (newValue, oldValue) {
        if (!newValue) {
          this.$emit("updateList");
        }
      }
    }
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.visible = true;
    },
    async editShow(obj) {
      // 格式化日期
      // obj.reLeaseTime = obj.reLeaseTime === undefined ? new Date() : moment(obj.reLeaseTime).format('YYYY-MM-DD HH:mm:ss')
      this._resetData();
      this.actionType = "edit";

      this.form = obj;
      this.visible = true;
    },
    submit() {
      this.$refs["refForm"].validate().then(() => {
        let api;
        switch (this.actionType) {
          case "add":
            console.log("add");
            api = new RentalAddInfo();
            break;
          case "edit":
            console.log("edit");
            api = new RentalEditInfo();
            break;
          default:
            return false;
        }
        // 取的是后台服务器时间，前端需要过滤掉时间(或者格式化时间)，否则后端校验异常
        // this.form.reLeaseTime = moment(this.form.reLeaseTime).format('YYYY-MM-DD HH:mm:ss');
        delete this.form.lastHeartbeatTime
        delete this.form.createTime
        delete this.form.updateTime
        api.post(this.form).then((response, any) => {
          if (0 === parseInt(response.data.code)) {
            message.success(response.data.msg);
            // 触发主页面组件定义事件
            this.$emit("updateList", response.data.data);
            this.visible = false;
          } else {
            message.error(response.data.msg);
          }
        });
      }).catch(() => {});
    },

    // reset data
    _resetData() {
      this.form = {
        id: undefined,
        reId: undefined,
        reName: undefined,
        reAisle: undefined,
        reStatus: undefined,
        reArgs: undefined,
        reIsAuthorized: undefined,
        remark: undefined,
      };
    },
  },
};
</script>

<style lang="less">
@import url("style");
</style>
