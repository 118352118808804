import { createRouter, createWebHashHistory } from 'vue-router'
import store from "@/store"
import Login from '@/views/Login'
import Home from '@/views/Home'
import PaySuccess from '@/views/PaySuccess'
import AuthorityManage from '@/views/Home/tenantSetting/authorityManage'
import PileManage from '@/views/Home/tenantSetting/pileManage/index'
import ChargerManage from '@/views/Home/tenantSetting/chargerManage/index'
import TradeManage from '@/views/Home/tenantSetting/tradeManage/index'
import EmployeeManage from '@/views/Home/tenantSetting/employeeManage'
import RoleManage from '@/views/Home/tenantSetting/roleManage'
import Dashboard from '@/views/Home/dashboard'
import RentalManage from "@/views/Home/tenantSetting/rentalManage";
import DeviceManage from "@/views/Home/tuya/deviceManage";
import DeviceLogs from "@/views/Home/tuya/deviceLogs";


const routes = [
  {
    path: "/",
    name: "Login",
    component: Login
  },
  {
    path: "/PaySuccess",
    name: "PaySuccess",
    component: PaySuccess
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      // token存在 进入该路由，不存在跳转到登陆页面
      if (store.getters.getToken) {
        //console.log(store.getters.getToken)
        next()
      } else {
        next('/Login')
      }
    },
    children: [
      {
        path: "/DeviceManage",
        name: "DeviceManage",
        component: DeviceManage
      },
      {
        path: "/DeviceLogs",
        name: "DeviceLogs",
        component: DeviceLogs
      },
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: Dashboard
      },
      {
        path: "/AuthorityManage",
        name: "AuthorityManage",
        component: AuthorityManage
      },
      {
        path: "/PileManage",
        name: "PileManage",
        component: PileManage
      },
      {
        path: "/ChargerManage",
        name: "ChargerManage",
        component: ChargerManage
      },
      {
        path: "/RentalManage",
        name: "RentalManage",
        component: RentalManage
      },
      {
        path: "/TradeManage",
        name: "TradeManage",
        component: TradeManage
      },
      {
        path: "/EmployeeManage",
        name: "EmployeeManage",
        component: EmployeeManage
      },
      {
        path: "/RoleManage",
        name: "RoleManage",
        component: RoleManage
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: routes
})

export default router
