<template>
  <Modal
    id="AuthorityEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="authorityForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                  <Col span="10">
                    <FormItem :label="$t('authority.field.a_id_aut')" name="aIdAut">
                        <!--关联表的下拉选择-->
                        <Select
                                v-model:value="form.aIdAut"
                                :placeholder="$t('authority.validate.required.a_id_aut')"
                                :dropdownStyle="{ zIndex: 9999999950 }"
                        >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['authorityList']" :value="item['idAut']">{{ item['name'] }}</SelectOption>
                        </Select>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('authority.field.name')" name="name">
                                            <Input :placeholder="$t('authority.validate.required.name')"
                                                   v-model:value="form.name">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('authority.field.resource_name')" name="resourceName">
                                            <Input :placeholder="$t('authority.validate.required.resource_name')"
                                                   v-model:value="form.resourceName">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('authority.field.type')" name="type">
                      <Select
                              v-model:value="form.type"
                              :placeholder="$t('authority.validate.required.type')"
                              :options="selectList.typeList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      </Select>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('authority.field.sort')" name="sort">
                                            <InputNumber :placeholder="$t('authority.validate.required.sort')"
                                                   v-model:value="form.sort">
                                            </InputNumber>
                  </FormItem>
                  </Col>
                <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { AuthorityAdd, AuthorityEdit, AuthorityListAll } from "@/api/authority";

export default {
  name: "AuthorityEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idAut : "",
        aIdAut : "",
        name : "",
        resourceName : "",
        type : "",
        sort : "",
      },
      fileList: {
      },
      selectList: {
        authorityList: [],
        typeList: [{value: 1,label: '菜單'},{value: 2,label: '按鈕'}],
      },
      validateRules: {
        idAut: [
          {
            required: false,
            message: this.$t("authority.validate.required.id_aut"),
            trigger: "change",
            type: "number",
          },
        ],
        aIdAut: [
          {
            required: false,
            message: this.$t("authority.validate.required.a_id_aut"),
            trigger: "change",
            type: "number",
          },
        ],
        name: [
          {
            required: true,
            message: this.$t("authority.validate.required.name"),
            trigger: "change",
          },
        ],
        resourceName: [
          {
            required: true,
            message: this.$t("authority.validate.required.resource_name"),
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: this.$t("authority.validate.required.type"),
            trigger: "change",
            type: "number",
          },
        ],
        sort: [
          {
            required: true,
            message: this.$t("authority.validate.required.sort"),
            trigger: "change",
            type: "number",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  watch: {
      "visible": {
          deep: true,
          handler: function (newValue, oldValue) {
              if (!newValue) {
                  this.$emit("updateList");
              }
          }
      }
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.getAuthorityList();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.getAuthorityList();
      this.visible = true;
    },
    submit() {
      this.$refs["authorityForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new AuthorityAdd();
              break;
            case "edit":
              console.log("edit");
              api = new AuthorityEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList",response.data.data);
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    getAuthorityList() {
      const api = new AuthorityListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.authorityList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    // reset data
    _resetData() {
      this.form = {
        idAut : "",
        aIdAut : "",
        name : "",
        resourceName : "",
        type : "",
        sort : "",
      };
    },
  },
};
</script>

<style lang="less">
</style>
