<template>
  <Row id="Employee">
    <Col :span="24" class="title">
      <TypographyTitle>{{ $t('nav.name.EmployeeManage') }}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <!-- <Form :model="searchForm">
            <Row :gutter="30">
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('member.name.search_user_name')" name="search">
                  <Input :placeholder="$t('member.name.search_user_name')" v-model:value="searchForm.search"></Input>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('public.field.status')">
                  <Select v-model:value="searchForm.status" :placeholder="$t('public.placeholder.please_select')">
                    <SelectOption :value="0">{{ $t('public.placeholder.please_select') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('public.status.1') }}</SelectOption>
                    <SelectOption :value="2">{{ $t('public.status.2') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8" class="text-right">
                <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>&nbsp;
                <Button @click="search_reset">{{$t('public.name.reset')}}</Button>
              </Col>
            </Row>
          </Form> -->
        </Col>
        <Col :span="24">
          <Table :columns="columns"
                 :dataSource="list"
                 :scroll="{ x: 1500, y: 'calc(100vh - 455px)' }"
                 rowKey="idEmp" :loading="loading"
                 :pagination="tablePagination"
                 @change="chang_page">
            <!-- 标题插槽-->
            <template #headerCell="{ column }">
              <template v-if="column.dataIndex === 'action'">
                <span>
                {{ $t("public.table.action") }}
                <Button v-if="checkPermission('EmployeeManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined/>
                </Button>
              </span>
              </template>
            </template>
            <!-- 内容插槽-->
            <template #bodyCell="{column, record}">
              <template v-if="column.dataIndex === 'action'">
                <div>
                  <div class="pc-display">
                    <Button type="default" size="small" @click="detail(record)">{{ $t("public.name.detail") }}</Button>
                    <span>&nbsp;</span>
                    <Button v-if="checkPermission('EmployeeManage.Edit')" type="primary" size="small" @click="edit(record)">
                      {{ $t("public.name.edit") }}
                    </Button>
                    <span>&nbsp;</span>
                    <Button v-if="checkPermission('EmployeeManage.Delete')" type="primary" danger size="small"
                            @click="del(record.idEmp)">{{ $t("public.name.del") }}
                    </Button>
                  </div>
                </div>
              </template>
            </template>
          </Table>
        </Col>
      </Row>
    </Col>
    <EmployeeDetail ref="refEmployeeDetail"></EmployeeDetail>
    <EmployeeEdit ref="refEmployeeEdit" @updateList="get_list"></EmployeeEdit>
  </Row>
</template>
<script>
export default {
  name: "EmployeeManage",
}
</script>
<script setup>
import {createVNode, onMounted, reactive, ref, toRefs} from "vue";
import {
  TypographyTitle,
  Row,
  Col,
  Table,
  Button,
  Dropdown,
  Menu,
  Modal,
  message
} from "ant-design-vue";
import {DownOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {EmployeeList, EmployeeDelete} from "@/api/employee";
import EmployeeDetail from "./EmployeeDetail";
import EmployeeEdit from "./EmployeeEdit";

import {useI18n} from "vue-i18n";
import {useStore} from "vuex";

const store = useStore()
const i18n = useI18n()

const refEmployeeEdit = ref()
const refEmployeeDetail = ref()

const statusList = [{value: 0, label: 'Off'}, {value: 1, label: 'On'}]

let loading = ref(false)
let list = ref([])
const columns = [
  {title: i18n.t("employee.table.id_emp"), fixed: "left", width: 60, ellipsis: true, dataIndex: "idEmp", key: "idEmp"},
  {title: i18n.t("employee.table.id_rol"), width: 120, ellipsis: true, dataIndex: "roleName",  key: "roleName"},
  {title: i18n.t("employee.table.account"), width: 120, ellipsis: true, dataIndex: "account", key: "account"},
  // {title: i18n.t("employee.table.password"), width: 60, ellipsis: true, dataIndex: "password", key: "password"},
  // {title: i18n.t("employee.table.salt"), width: 60, ellipsis: true, dataIndex: "salt", key: "salt"},
  {title: i18n.t("employee.table.last_login_time"), width: 120, ellipsis: true, dataIndex: "lastLoginTime", key: "lastLoginTime"},
  {title: i18n.t("employee.table.register_time"), width: 120, ellipsis: true, dataIndex: "registerTime", key: "registerTime"},
  {
    title: i18n.t("employee.table.login_failure_counter"),
    width: 120, ellipsis: true,
    dataIndex: "loginFailureCounter",
    key: "loginFailureCounter"
  },
  {title: i18n.t("employee.table.email"), width: 180, ellipsis: true, dataIndex: "email", key: "email"},
  // {title: i18n.t("employee.table.remark"), width: 60, ellipsis: true, dataIndex: "remark", key: "remark"},
  {
    title: i18n.t("employee.table.status"), width: 60, ellipsis: true, dataIndex: "status", key: "status", customRender: ({text}) => {
      return statusList.find(item => item.value === text).label
    }
  },
  {title: i18n.t("employee.table.name"), width: 120, ellipsis: true, dataIndex: "name", key: "name"},
  {title: i18n.t("employee.table.commission_rate"), width: 120, ellipsis: true, dataIndex: "commissionRate", key: "commissionRate"},
  // {title: i18n.t("employee.table.yedpay_api_key"), width: 60, ellipsis: true, dataIndex: "yedpayApiKey", key: "yedpayApiKey"},
  // {title: i18n.t("employee.table.yedpay_sign_key"), width: 60, ellipsis: true, dataIndex: "yedpaySignKey", key: "yedpaySignKey"},
  {title: i18n.t("employee.table.tenant_key"), width: 120, ellipsis: true, dataIndex: "tenantKey", key: "tenantKey"},
  {key: "action", fixed: "right", width: 180, ellipsis: true, dataIndex: "action"},
]
const data = reactive({
  searchForm: {},
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
})
const {searchForm, tablePagination} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  const api = new EmployeeList();
  const getData = {
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
  }
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  });
}

function add() {
  refEmployeeEdit.value.addShow();
}

function detail(obj) {
  refEmployeeDetail.value.detailShow(obj);
}

function edit(obj) {
  refEmployeeEdit.value.editShow(obj);
}

function del(idEmp) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new EmployeeDelete();
      api.post({idEmp: idEmp}).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(response.data.msg);
        } else {
          message.error(response.data.msg);
        }
      });
    },
  });
}

function search_submit() {
  get_list()
}

function search_reset() {
  searchForm.value = {
    // search: "",
    // status: 0,
    // order: "",
  }
  get_list()
}

onMounted(()=>{
  get_list();
})

</script>

<style lang="less">
@import url("style.less");
</style>
