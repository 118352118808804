<template>
  <Modal
      id="EmployeeEdit"
      width="60%"
      :visible="visible"
      @cancel="visible = !visible"
      @ok="submit"
      :title="title"
  >
    <Row justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="employeeForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('employee.field.id_rol')" name="idRol">
                    <!--关联表的下拉选择-->
                    <Select
                        v-model:value="form.idRol"
                        :placeholder="$t('employee.validate.required.id_rol')"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                    >
                      <SelectOption :key="sk" v-for="(item,sk) in roleList" :value="item['idRol']">{{
                          item['name']
                        }}
                      </SelectOption>
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('employee.field.account')" name="account">
                    <Input :placeholder="$t('employee.validate.required.account')"
                           v-model:value="form.account">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10" v-if="actionType === 'add'">
                  <FormItem :label="$t('employee.field.password')" name="password">
                    <InputPassword :placeholder="$t('employee.validate.required.password')"
                                   v-model:value="form.password">
                    </InputPassword>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('employee.field.email')" name="email">
                    <Input :placeholder="$t('employee.validate.required.email')"
                           v-model:value="form.email">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('employee.field.status')" name="status">
                    <Select
                        v-model:value="form.status"
                        :placeholder="$t('employee.validate.required.status')"
                        :options="statusList"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                    >
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('employee.field.name')" name="name">
                    <Input :placeholder="$t('employee.validate.required.name')"
                           v-model:value="form.name">
                    </Input>
                  </FormItem>
                </Col>
<!--                <Col span="10">-->
<!--                  <FormItem :label="$t('employee.field.commission_rate')" name="commissionRate">-->
<!--                    <InputNumber-->
<!--                        v-model:value="form.commissionRate"-->
<!--                        :placeholder="$t('employee.validate.required.commission_rate')"-->
<!--                        :step="0.01"-->
<!--                    />-->
<!--                  </FormItem>-->
<!--                </Col>-->
<!--                <Col span="10">-->
<!--                  <FormItem :label="$t('employee.field.yedpay_api_key')" name="yedpayApiKey">-->
<!--                    <Input :placeholder="$t('employee.validate.required.yedpay_api_key')"-->
<!--                           v-model:value="form.yedpayApiKey">-->
<!--                    </Input>-->
<!--                  </FormItem>-->
<!--                </Col>-->
<!--                <Col span="10">-->
<!--                  <FormItem :label="$t('employee.field.yedpay_sign_key')" name="yedpaySignKey">-->
<!--                    <Input :placeholder="$t('employee.validate.required.yedpay_sign_key')"-->
<!--                           v-model:value="form.yedpaySignKey">-->
<!--                    </Input>-->
<!--                  </FormItem>-->
<!--                </Col>-->
                <Col span="10">
                  <FormItem :label="$t('employee.field.tenant_key')" name="tenantKey">
                    <Input :placeholder="$t('employee.validate.required.tenant_key')"
                           v-model:value="form.tenantKey">
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10"></Col>
                <Col span="22">
                  <FormItem :label="$t('employee.field.remark')" name="remark">
                      <Textarea
                          v-model:value="form.remark"
                          :placeholder="$t('employee.validate.required.remark')"
                          :auto-size="{ minRows: 5, maxRows: 10 }"
                          showCount=""
                      />
                  </FormItem>
                </Col>
                <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "EmployeeEdit",
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  InputPassword,
  Textarea,
  message,
  Select,
  SelectOption
} from "ant-design-vue";
import {EmployeeAdd, EmployeeEdit} from "@/api/employee";
import {RoleListAll} from "@/api/role"
import {reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";

const store = useStore()
const i18n = useI18n()
const emits = defineEmits(['updateList'])

const statusList = [{value: 0, label: 'Off'}, {value: 1, label: 'On'}]

const employeeForm = ref()

let title = ref("")
let visible = ref(false)
let actionType = ref("")
let roleList = ref([])
let fileList = ref([])

const validateRules = {
  idEmp: [
    {
      required: true,
      message: i18n.t("employee.validate.required.id_emp"),
      trigger: "change",
      type: "number",
    },
  ],
  idRol: [
    {
      required: true,
      message: i18n.t("employee.validate.required.id_rol"),
      trigger: "change",
      type: "number",
    },
  ],
  account: [
    {
      required: true,
      message: i18n.t("employee.validate.required.account"),
      trigger: "change",
    },
  ],
  password: [
    {
      required: true,
      message: i18n.t("employee.validate.required.password"),
      trigger: "change",
    },
  ],
  salt: [
    {
      required: true,
      message: i18n.t("employee.validate.required.salt"),
      trigger: "change",
    },
  ],
  lastLoginTime: [
    {
      required: true,
      message: i18n.t("employee.validate.required.last_login_time"),
      trigger: "change",
    },
  ],
  registerTime: [
    {
      required: true,
      message: i18n.t("employee.validate.required.register_time"),
      trigger: "change",
    },
  ],
  loginFailureCounter: [
    {
      required: true,
      message: i18n.t("employee.validate.required.login_failure_counter"),
      trigger: "change",
      type: "number",
    },
  ],
  email: [
    {
      required: true,
      message: i18n.t("employee.validate.required.email"),
      trigger: "change",
      type: "email",
    },
  ],
  remark: [
    {
      required: true,
      message: i18n.t("employee.validate.required.remark"),
      trigger: "change",
    },
  ],
  status: [
    {
      required: true,
      message: i18n.t("employee.validate.required.status"),
      trigger: "change",
      type: "number",
    },
  ],
  name: [
    {
      required: true,
      message: i18n.t("employee.validate.required.name"),
      trigger: "change",
    },
  ],
  commissionRate: [
    {
      required: true,
      message: i18n.t("employee.validate.required.commission_rate"),
      trigger: "change",
      type: "number",
    },
  ],
  yedpayApiKey: [
    {
      required: true,
      message: i18n.t("employee.validate.required.yedpay_api_key"),
      trigger: "change",
    },
  ],
  yedpaySignKey: [
    {
      required: true,
      message: i18n.t("employee.validate.required.yedpay_sign_key"),
      trigger: "change",
    },
  ],
  tenantKey: [
    {
      required: true,
      message: i18n.t("employee.validate.required.tenant_key"),
      trigger: "change",
    },
  ],
}

const data = reactive({
  form: {
    idEmp: "",
    idRol: "",
    account: "",
    password: "",
    email: "",
    remark: "",
    status: "",
    name: "",
    commissionRate: "",
    yedpayApiKey: "",
    yedpaySignKey: "",
    tenantKey: "",
  },
})
const {form} = toRefs(data)

function addShow() {
  title.value = i18n.t("public.name.add")
  actionType.value = "add";
  _resetData();
  getRoleList();
  visible.value = true;
}

async function editShow(obj) {
  title.value = i18n.t("public.name.edit")
  _resetData();
  actionType.value = "edit";
  form.value = JSON.parse(JSON.stringify(obj));
  getRoleList();
  visible.value = true;
}

function submit() {
  employeeForm.value.validate().then(() => {
    let api;
    switch (actionType.value) {
      case "add":
        api = new EmployeeAdd();
        break;
      case "edit":
        api = new EmployeeEdit();
        break;
      default:
        return false;
    }
    api.post(form.value).then((response, any) => {
      if (0 === parseInt(response.data.code)) {
        message.success(response.data.msg);
        emits("updateList", response.data.data);
        visible.value = false;
      } else {
        message.error(response.data.msg);
      }
    });
  }).catch(() => {
  });
}

function getRoleList() {
  const api = new RoleListAll();
  api.get().then((response) => {
    if (0 === parseInt(response.data.code)) {
      roleList.value = response.data.data;
    } else {
      message.error(response.data.msg);
    }
  });
}

function _resetData() {
  form.value = {
    idEmp: "",
    idRol: undefined,
    account: "",
    password: "",
    email: "",
    remark: "",
    status: undefined,
    name: "",
    commissionRate: "",
    yedpayApiKey: "",
    yedpaySignKey: "",
    tenantKey: "",
  };
}

defineExpose({
  addShow,
  editShow
})
</script>

<style lang="less">
</style>
