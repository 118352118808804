<template>
  <div id="App">
    <ConfigProvider>
      <router-view />
    </ConfigProvider>
  </div>
</template>
<script>
export default {
  name: "App",
}
</script>
<script setup>
import { ConfigProvider } from "ant-design-vue";
import {onMounted} from "vue";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";

const store = useStore()
const i18n = useI18n()

onMounted(()=>{
  store.commit("reset");
  switch (store.getters.getLanguage) {
    case "zhTW":
      i18n.locale.value = "zhTW";
      break;
    case "enUS":
      i18n.locale.value = "enUS";
      break;
    case "zhCN":
      i18n.locale.value = "zhCN";
      break;
  }
})
</script>

