<template>
  <Modal
      id="FormModel"
      width="50%"
      :open="open"
      @cancel="open = !open"
      @ok="submit"
      :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" >
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('chargerManage.table.id')" name="id">
                    <Input
                        :disabled="true"
                        :placeholder="$t('chargerManage.validate.id')"
                        v-model:value="form.id">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('chargerManage.table.identifier')" name="identifier">
                    <Input
                        :disabled="actionType==='edit'"
                        :placeholder="$t('chargerManage.validate.identifier')"
                        v-model:value="form.identifier">
                    </Input>
                  </FormItem>
                </Col>
             
                <Col span="10">
                  <FormItem :label="$t('chargerManage.table.cName')" name="cName">
                    <Input :placeholder="$t('chargerManage.validate.cName')"
                           v-model:value="form.cName">
                    </Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('chargerManage.table.cPort')" name="cPort">
                    <InputNumber
                        :disabled="actionType==='edit'"
                        :placeholder="$t('chargerManage.validate.cPort')"
                        v-model:value="form.cPort"
                        min="0"
                        max="99999"
                        style="width: 100%"
                    >
                    </InputNumber>
                  </FormItem>
                </Col>
              
                <Col span="10">
                  <FormItem :label="$t('chargerManage.table.cPower')" name="cPower">
                    <InputNumber
                        :placeholder="$t('chargerManage.validate.cPower')"
                        v-model:value="form.cPower"
                        min="0"
                        max="99999"
                        style="width: 100%"
                    >
                    </InputNumber>
                  </FormItem>
                </Col>
           
                <Col span="10">
                  <FormItem :label="$t('chargerManage.table.cType')" name="cType">
                    <Select
                        v-model:value="form.cType"
                        :placeholder="$t('chargerManage.validate.cType')"
                        :options="chargingPortTypeList"
                        :dropdownStyle="{ zIndex: 9999999950 }"
                    />
                  </FormItem>
                </Col>
                <Col span="10"></Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "EditModel"
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  InputNumber,
  message,
  Select,
} from "ant-design-vue";

import {ChargerAddInfo, ChargerEditInfo} from "@/api/charger";
import {ref} from "vue";

import {useI18n} from "vue-i18n";

const i18n = useI18n()

const emits = defineEmits(['updateList'])

const refForm = ref()

let title = ref("")
let open = ref(false)
let actionType = ref("")
const chargingPortTypeList = [
  {value: '0', label: 'Type 1'},
  {value: '1', label: 'Type 2'},
  {value: '2', label: 'CHAdeMO'},
  {value: '3', label: 'CCS Combo Type 1'},
  {value: '4', label: 'CCS Combo Type 2'},
]
let form = ref({
  id: undefined,
  identifier: undefined,
  reId: undefined,
  evName: undefined,
  evStatus: undefined,
  evType: undefined,
  evArgs: undefined,
  remark: undefined,
})
let fileList = ref([])
const validateRules = {
  id: [
    {
      required: false,
      message: i18n.t("chargerManage.validate.id"),
      trigger: "change",
    },
  ],
  identifier: [
    {
      required: true,
      message: i18n.t("chargerManage.validate.identifier"),
      trigger: "change",
    },
  ],
  cName: [
    {
      required: true,
      message: i18n.t("chargerManage.validate.cName"),
      trigger: "change",
    },
  ],
  cPort: [
    {
      required: true,
      message: i18n.t("chargerManage.validate.cPort"),
      trigger: "change",
    },
  ],
  cPower: [
    {
      required: true,
      message: i18n.t("chargerManage.validate.cPower"),
      trigger: "change",
    },
  ],
  cType: [
    {
      required: true,
      message: i18n.t("chargerManage.validate.cType"),
      trigger: "change",
    },
  ],
  cStatus: [
    {
      required: true,
      message: i18n.t("chargerManage.validate.cStatus"),
      trigger: "change",
    },
  ],
  remark: [
    {
      required: true,
      message: i18n.t("chargerManage.validate.otherArgs"),
      trigger: "change",
    },
  ],
}

function addShow() {
  actionType.value = "add";
  title.value = i18n.t("public.name.add");
  _resetData();
  open.value = true;
}

function editShow(obj) {
  _resetData();
  actionType.value = "edit";
  title.value = i18n.t("public.name.edit");
  form.value = JSON.parse(JSON.stringify(obj));
  open.value = true;
}

function submit() {
  console.log(refForm.value)
  refForm.value.validate().then(() => {
    let api;
    switch (actionType.value) {
      case "add":
        api = new ChargerAddInfo();
        break;
      case "edit":
        api = new ChargerEditInfo();
        break;
      default:
        return false;
    }
    // 取的是后台服务器时间，前端需要过滤掉时间(或者格式化时间)，否则后端校验异常
    delete form.value.createTime
    delete form.value.updateTime
    api.post(form.value).then(response => {
      if (0 === parseInt(response.data.code)) {
        message.success(response.data.msg);
        // 触发主页面组件定义事件
        emits("updateList", response.data.data);
        open.value = false;
      } else {
        message.error(response.data.msg);
      }
    });
  }).catch(() => {});
}

function _resetData() {
  form.value = {
    id: undefined,
    identifier: undefined,
    reId: undefined,
    evName: undefined,
    evStatus: undefined,
    evType: undefined,
    evArgs: undefined,
    remark: undefined,
  };
}
defineExpose({
  addShow,
  editShow
});
</script>

<style lang="less">
@import url("style");
</style>
