<template>
  <Row id="Login" align="middle">
    <Col class="header" :span="24">
      <span>支付成功</span>
      <Button type="primary" @click="closeWindow">關閉頁面</Button>
    </Col>
    
  </Row>
</template>


<script>
import { Row, Col, Form, Input, Image, Radio, message,Button } from "ant-design-vue";
import { LoginOutlined, UserOutlined } from "@ant-design/icons-vue";

export default {
  name: "Login",
  components: {
    Row,
    Col,
    LoginOutlined,
    UserOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Button,
  },
  data() {
    return {
      
    };
  },
  methods: {
    
    closeWindow() {
      // console.log("关闭浏览器");
      var userAgent = navigator.userAgent;
      if (userAgent.indexOf("MSIE") > 0) {
        if (userAgent.indexOf("MSIE 6.0") > 0) {
          window.opener = null;
          window.close();
        } else {
          window.open("", "_top");
          window.top.close();
        }
      } else if (userAgent.indexOf("Firefox") != -1 || userAgent.indexOf("Chrome") != -1) {
        window.location.href = "about:blank "; //火狐默认状态非window.open的页面window.close是无效的
        //window.history.go(-2);
      } else {
        window.opener = null;
        window.open("about:blank", "_self");
        window.close();
      }
    },
  },

}

</script>


