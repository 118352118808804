<template>
  <Row id="TradeManage">
    <!-- 加载状态-->
    <Spin size="large" :spinning="false"/>
    <!-- 标签-->
    <Col :span="24" class="title">
      <TypographyTitle>{{$t('nav.name.TradeManage')}}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <!-- 查询表单-->
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('tradeManage.table.tradeNo')" name="tradeNo">
                  <Input :placeholder="$t('tradeManage.validate.tradeNo')" v-model:value="searchForm.tradeNo" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('tradeManage.table.identifier')" name="identifier">
                  <Input :placeholder="$t('tradeManage.validate.identifier')" v-model:value="searchForm.identifier" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('tradeManage.table.port')">
                  <InputNumber
                      :placeholder="$t('tradeManage.validate.port')"
                      v-model:value="searchForm.port"
                      min="0"
                      max="99999"
                      style="width: 100%"
                  >
                  </InputNumber>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('tradeManage.table.startTime')" name="startTime">
                  <Input :placeholder="$t('tradeManage.validate.startTime')" v-model:value="searchForm.startTime" allowClear></Input>
                </FormItem>
              </Col>
            </Row>
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('tradeManage.table.actualEndTime')" name="actualEndTime">
                  <Input :placeholder="$t('tradeManage.validate.actualEndTime')" v-model:value="searchForm.actualEndTime" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('tradeManage.table.tradeStatus')" name="tradeStatus">
                  <Select v-model:value="searchForm.tradeStatus" :placeholder="$t('tradeManage.validate.tradeStatus')" allowClear>
                    <SelectOption :value="0">{{ $t('tradeManage.tradeStatusList.0') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('tradeManage.tradeStatusList.1') }}</SelectOption>
                    <SelectOption :value="2">{{ $t('tradeManage.tradeStatusList.2') }}</SelectOption>
                    <SelectOption :value="3">{{ $t('tradeManage.tradeStatusList.3') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6"></Col>
              <Col :span="6"></Col>
            </Row>
            <Row :gutter="16">
              <Col :span="8"></Col>
              <Col :span="8" :offset="8" style="text-align: right;margin-bottom: 5px">
                <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>&nbsp;
                <Button @click="search_reset">{{$t('public.name.reset')}}</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <!-- 列表-->
      <Row>
        <Col :span="24">
          <!--          <div style="margin-bottom: 5px">-->
          <!--            <Button type="primary" size="default" @click="add()">新增</Button>-->
          <!--            <span>&nbsp;</span>-->
          <!--            <Button type="primary" size="default" danger @click="delByIds()">批量删除</Button>-->
          <!--          </div>-->
          <Table
              :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
              :columns="columns"
              :dataSource="list"
              rowKey="id"
              :loading="loading"
              :pagination="tablePagination"
              :scroll="{ x: 1500, y: 'calc(100vh - 455px)' }"
              @change="chang_page">
            <!-- 插槽 -->
            <template #isEnableSwitchTitle>
              <span>
                {{$t("tradeManage.table.isEnable")}}
              </span>
            </template>
            <template #isEnableSwitch="{text}">
              <Switch :checked="text.isEnable === '1'" @click="e => switchClick(e, text)" checked-children="开" un-checked-children="关" />
            </template>

            <template #status="{text}">
              <div>
                <Tag v-if="text === 1 " color="#547fd3">进行中</Tag>
                <Tag v-else-if="text === 2 " color="#87d068">已完成</Tag>
                <Tag v-else color="#f50">异常</Tag>
              </div>
            </template>

            <!-- 插槽 -->
            <template #actionTitle>
              <span>
                {{$t("public.table.action")}}
              </span>
            </template>
            <template #action="{text}">
              <div>
                <div class="pc-display">
                  <Button type="primary" danger size="small" @click="endTransaction(text)">{{$t("tradeManage.table.action.endTransaction")}}</Button>
                </div>
              </div>
            </template>
          </Table>
        </Col>
      </Row>
    </Col>
    <TradeDetail ref="refDetail" />
    <FormModel ref="refFormModel" @updateList="get_list" />

  </Row>
</template>

<script>
import {createVNode} from "vue";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  Tag,
  Typography,
  Spin
} from "ant-design-vue";
import {DownOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";

import {TradeGetListByPage, TradeDelInfo, TradeEndInfo} from "@/api/trade";
import TradeDetail from "./TradeDetail";
import FormModel from "./FormModel";

export default {
  name: "TradeManage",
  components: {
    Row,
    Col,
    Table,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Input,
    InputNumber,
    Select,
    SelectOption: Select.Option,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    Switch,
    TradeDetail,
    FormModel,
    Spin
  },
  data() {
    return {
      searchForm: {},
      tablePagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      list: [],
      selectedRowKeys: [],
      columns: [
        // { title: this.$t("tradeManage.table.index"), fixed: "left", width: 60, ellipsis: true, customRender: ({text, record, index, column}) => `${(this.tablePagination.current-1) * this.tablePagination.pageSize +  index + 1}`},
        { title: this.$t("tradeManage.table.id"), width: 80, ellipsis: true, dataIndex: "id", key: "id" },
        { title: this.$t("tradeManage.table.tradeNo"), width: 150, ellipsis: true, dataIndex: "tradeNo", key: "tradeNo"},
        { title: this.$t("tradeManage.table.identifier"), width: 150, ellipsis: true, dataIndex: "identifier", key: "identifier" , customRender: ({text, record, index, column}) => {
            return record.identifier + "-" + record.port
          }},
        // { title: this.$t("tradeManage.table.port"), width: 110, ellipsis: true, dataIndex: "port", key: "port" },
        { title: this.$t("tradeManage.table.startTime"), width: 150, ellipsis: true, dataIndex: "startTime", key: "startTime" },
        { title: this.$t("tradeManage.table.actualEndTime"), width: 150, ellipsis: true, dataIndex: "actualEndTime", key: "actualEndTime" },

        { title: this.$t("tradeManage.table.currentElectricity"), ellipsis: true, width: 150, dataIndex: "currentElectricity", key: "currentElectricity" },

        { title: this.$t("tradeManage.table.remark"), ellipsis: true, width: 220, dataIndex: "stopReason", key: "stopReason" },

        { title: this.$t("tradeManage.table.tradeStatus"), width: 100, ellipsis: true, dataIndex: "tradeStatus", key: "tradeStatus", slots: { title: "statusTitle", customRender: "status" }},
          // 电桩端口充电事务应仅供查看，不可修改
        { key: "action", fixed: "right", width: 80, ellipsis: true, slots: { title: "actionTitle", customRender: "action" } },
      ],
      isClickLoading: false
    };
  },
  methods: {
    chang_page(pagination) {
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      this.loading = true;
      const api = new TradeGetListByPage();
      // 构建查询参数
      const getData = {
        ...this.searchForm,
        page: this.tablePagination.current,
        limit: this.tablePagination.pageSize,
      }
      api.get(getData)
          .then((response) => {
            this.loading = false;
            if (0 === parseInt(response.data.code)) {
              const dataTmp = response.data.data;
              this.tablePagination.current = parseInt(dataTmp.current);
              this.tablePagination.pageSize = parseInt(dataTmp.pageSize);
              this.tablePagination.total = parseInt(dataTmp.total);
              this.list = dataTmp.data
            } else {
              this.list = [];
            }
          });
    },
    endTransaction(obj) {
      if (obj.tradeStatus === 1) {
        Modal.confirm({
          title: this.$t("public.name.are_you_sure_delete") + "?",
          content: this.$t("public.name.action_cannot_resumed"),
          icon: createVNode(ExclamationCircleOutlined),
          okText: this.$t("public.name.yes"),
          okType: "danger",
          cancelText: this.$t("public.name.no"),
          onOk: () => {
            const api = new TradeEndInfo();
            api.post(obj).then((response) => {
              parseInt(response.data.code) === 0 ? message.success(response.data.msg) : message.error(response.data.msg);
              // 刷新列表
              this.get_list();
            });

          },
        });
      } else {
        message.error("The transaction is complete");
      }
    },
    add() {
      this.$refs.refFormModel.addShow();
    },
    edit(obj) {
      this.$refs.refFormModel.editShow(obj);
    },
    del(obj) {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new TradeDelInfo();
          let l = []
          l.push(obj.id)
          api.post({ids: l}).then((response) => {
            parseInt(response.data.code) === 0 ? message.success(response.data.msg) : message.error(response.data.msg);
            // 刷新列表
            this.get_list();
          });
        },
      });
    },
    onSelectChange(ids) {
      this.selectedRowKeys = ids
    },
    delByIds() {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new TradeDelInfo();
          api.post({ids: this.selectedRowKeys}).then((response) => {
            parseInt(response.data.code) === 0 ? message.success(response.data.msg) : message.error(response.data.msg);
            // 刷新列表
            this.get_list();
          });
        },
      });
    },
    search_submit() {
      this.tablePagination.current = 1
      this.tablePagination.pageSize = 10
      this.get_list()
    },
    // 重置查询表单
    search_reset() {
      this.searchForm = {}
      this.get_list()
    },
  },
  mounted() {
    this.get_list();
  },
}
</script>

<style lang="less">
@import url("style");
</style>
