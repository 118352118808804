<template>
  <Row id="body">
    <!-- 标签-->
    <Col :span="24" class="title">
      <TypographyTitle>{{ $t('nav.name.DeviceLogs') }}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <!-- 查询表单-->
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('DeviceLogs.table.deviceId')" name="deviceId">
                  <Input :placeholder="$t('DeviceLogs.validate.deviceId')" v-model:value="searchForm.deviceId" disabled></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('DeviceLogs.validate.types')" name="types">
                  <Select
                      :max-tag-count="3"
                      v-model:value="types"
                      mode="multiple"
                      :placeholder="$t('DeviceLogs.validate.types')"
                      style="width: 100%"
                      :options="typeList"
                      @change="handleTypeChange"
                  ></Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('DeviceLogs.validate.codes')" name="codes">
                  <Select
                      :max-tag-count="3"
                      v-model:value="codes"
                      mode="multiple"
                      :placeholder="$t('DeviceLogs.validate.codes')"
                      style="width: 100%"
                      :options="codeList"
                      @change="handleCodeChange"
                  ></Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('DeviceLogs.validate.startTime')" name="dateStrings">
                  <RangePicker
                      show-time
                      format="YYYY-MM-DD HH:mm:ss"
                      :presets="rangePresets"
                      style="width: 100%"
                      @change="onRangeChange"
                      v-model:value="dateStrings"
                  />
                </FormItem>
              </Col>
              <Col :span="6"></Col>
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8">
<!--              <Button v-if="checkPermission('ChargerManage.Add')" type="primary" size="default" @click="add()">{{ $t("public.name.add") }}</Button>-->
<!--              <span>&nbsp;</span>-->
<!--              <Button v-if="checkPermission('ChargerManage.Delete')" type="primary" size="default" danger @click="delByIds()">{{ $t("public.name.dels") }}</Button>-->
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <!-- 列表-->
      <Row>
        <Col :span="24">
          <Table
              :columns="columns"
              :dataSource="list"
              rowKey="id"
              :loading="loading"
              :scroll="{ x: 1500, y: 'calc(100vh - 410px)' }"
              :pagination="false"
              @change="chang_page">
            <template #bodyCell="{column, record}">
              <template v-if="column.dataIndex === 'eventId'">
                {{ $t('DeviceLogs.eventType.' + record.event_id)}}
              </template>
            </template>
          </Table>
          <div v-if="hasNext" style="width: 100%; text-align: center;margin-top: 5px"><Button @click="onClickLoading" >loading({{ tablePagination.pageSize }})...</Button></div>
        </Col>
      </Row>
    </Col>
  </Row>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "deviceLogs",
}
</script>
<script setup>
import {createVNode, onMounted, ref} from "vue";
import {
  Button,
  Col,
  Form,
  FormItem,
  TypographyTitle,
  Input,
  message,
  Row,
  Select,
  Table,
  RangePicker,
} from "ant-design-vue";
import {useI18n} from "vue-i18n";
import {DeviceOperaLog, DeviceSpecificationAttributes, DevicesStatusLog, DeviceStatusSet} from "@/api/tuya";
import {useRoute} from 'vue-router';
import dayjs from "dayjs";

const i18n = useI18n()
const route = useRoute()

let searchForm = ref({})
let tablePagination = ref({
  pageSizeOptions: ["10", "20", "50", "100"],
  showSizeChanger: true,
  current: 1,
  pageSize: 20,
  total: 0,
  lastRowKey: undefined,
})
let loading = ref(false)
let list = ref([])

let codes = ref([])
let codeList = ref([])

let types = ref([1,2,3,4,5,6,7,8,9,10])
let typeList = ref([
  {"label": i18n.t("DeviceLogs.eventType.1"), "value": 1},
  {"label": i18n.t("DeviceLogs.eventType.2"), "value": 2},
  {"label": i18n.t("DeviceLogs.eventType.3"), "value": 3},
  {"label": i18n.t("DeviceLogs.eventType.4"), "value": 4},
  {"label": i18n.t("DeviceLogs.eventType.5"), "value": 5},
  {"label": i18n.t("DeviceLogs.eventType.6"), "value": 6},
  {"label": i18n.t("DeviceLogs.eventType.7"), "value": 7},
  {"label": i18n.t("DeviceLogs.eventType.8"), "value": 8},
  {"label": i18n.t("DeviceLogs.eventType.9"), "value": 9},
  {"label": i18n.t("DeviceLogs.eventType.10"), "value": 10},
])


let dateStrings = ref()
let hasNext = ref(false)

let isZh = ref(i18n.locale.value === 'zhTW' || i18n.locale.value === 'zhCN')

const columns = [
  {title: i18n.t("DeviceLogs.table.eventTime"), width: 120, ellipsis: true, dataIndex: "timeStr", key: "timeStr"},
  {title: i18n.t("DeviceLogs.table.eventId"), width: 120, ellipsis: true, dataIndex: "eventId", key: "event_id"},
  {title: i18n.t("DeviceLogs.table.code"), fixed: "center", width: 120, ellipsis: true, dataIndex: "code", key: "code",
    customRender: ({text}) => {
      if (isZh.value) {
        const obj = codeList.value.find(item => item.value === text)
        return obj === undefined ? text : obj.label
      }
      return text
    }
  },
  {title: i18n.t("DeviceLogs.table.value"), fixed: "center", width: 120, ellipsis: true, dataIndex: "value", key: "value",
    customRender: ({text, record}) => {
      const obj = codeList.value.find(item => item.value === record.code)
      if (obj !== undefined && obj.unit !== undefined) {
        if (obj.unit === '% RH' || obj.unit === '℃') {
          return (text / 100).toFixed(2) + obj.unit
        }
        return text + obj.unit
      }
      return text
    }
  },
  {title: i18n.t("DeviceLogs.table.eventValue"), width: 80, ellipsis: true, dataIndex: "event_value", key: "event_value"},
  {title: i18n.t("DeviceLogs.table.eventFrom"), width: 120, ellipsis: true, dataIndex: "event_from", key: "event_from"},
  // {dataIndex: "action", key: "action", fixed: "right", width: 80, ellipsis: true},
]

const rangePresets = ref([
  {
    label: 'Last 7 Days',
    value: [dayjs().add(-7, 'd'), dayjs()],
  },
  {
    label: 'Last 14 Days',
    value: [dayjs().add(-14, 'd'), dayjs()],
  },
  {
    label: 'Last 30 Days',
    value: [dayjs().add(-30, 'd'), dayjs()],
  },
  {
    label: 'Last 90 Days',
    value: [dayjs().add(-90, 'd'), dayjs()],
  },
]);

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list(lastRowKey) {
  loading.value = true;
  const api = new DeviceOperaLog();
  // 构建查询参数
  const getData = {
    deviceId: searchForm.value.deviceId,
    type: searchForm.value.type,
    codes: searchForm.value.codes,
    dateStrings: searchForm.value.dateStrings,
    lastRowKey: lastRowKey,
    pageSize: tablePagination.value.pageSize,
  }
  api.get(getData).then((response) => {
    loading.value = false;

    if (response.data.msg !== undefined) {
      message.error(response.data.msg)
      return
    }

    hasNext.value = response.data.has_next
    const _lastKey = response.data.next_row_key


    if (lastRowKey !== undefined && lastRowKey !== _lastKey) {
      list.value = list.value.concat(response.data.logs)
      tablePagination.value.lastRowKey = _lastKey
    } else {
      list.value = response.data.logs
    }
    tablePagination.value.total = response.data.total;
  });
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  types.value = []
  searchForm.value.type = undefined
  codes.value = []
  searchForm.value.codes = undefined
  dateStrings.value = undefined
  get_list()
}

function handleTypeChange(e) {
  searchForm.value.type = cr(e)
}

function handleCodeChange(e) {
  searchForm.value.codes = cr(e)
}

function cr(e) {
  let cs = undefined
  e?.forEach(item => {
    if (cs === undefined) {
      cs = item
    } else {
      cs = cs + "," + item
    }
  })
  return cs
}

function onRangeChange(dates, dateStrings) {
  if (dates) {
    searchForm.value.dateStrings = dateStrings[0] + "," + dateStrings[1]
  }
}

// 加载更多数据
function onClickLoading() {
  get_list(tablePagination.value.lastRowKey)
}

// get_list()
onMounted(()=>{
  if (route.query.id) {
    searchForm.value.deviceId = route.query.id
    // 获取设备状态列表
    const api = new DeviceSpecificationAttributes();
    api.get({deviceId: route.query.id}).then((response) => {
      // console.log(response.data)
      // 拼接所有状态，并查询
      if (response.data) {
        response.data.status?.forEach(item => {
          let values = JSON.parse(item.values)
          codeList.value.push({
            value: item.code,
            label: isZh.value ? item.name : item.code,
            unit: values.unit
          })
        })
        get_list();
      }
    });
  }
})

</script>

<style lang="less">
@import url("style");
</style>
