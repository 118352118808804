import {Base} from "@/api/Base"

const api = "trade"

// 分页查询列表
export class TradeGetListByPage extends Base {
    path = api + "/getListByPage"
}

// 获取记录
export class TradeGetInfo extends Base {
    path = api + "/getInfo"
}

// 新增记录
export class TradeAddInfo extends Base {
    path = api + "/addInfo"
}

// 修改记录
export class TradeEditInfo extends Base {
    path = api + "/editInfo"
}

// 删除记录
export class TradeDelInfo extends Base {
    path = api + "/delInfo"
}

// 终止记录
export class TradeEndInfo extends Base {
    path = api + "/endInfo"
}


