import {Base} from "@/api/Base"

const api = "rental"

// 分页查询租户列表
export class RentalGetListByPage extends Base {
    path = api + "/getListByPage"
}

// 分页查询租户列表
export class RentalGetTenantList extends Base {
    path = api + "/getTenantList"
}

// 获取记录
export class RentalGetInfo extends Base {
    path = api + "/getInfo"
}

// 新增记录
export class RentalAddInfo extends Base {
    path = api + "/addInfo"
}

// 修改记录
export class RentalEditInfo extends Base {
    path = api + "/editInfo"
}

// 删除记录
export class RentalDelInfo extends Base {
    path = api + "/delInfo"
}

// 获取用户表中的租户标识列表
export class RentalUserGetTenantKeyList extends Base {
    path = api + "/getUserTenantList"
}



