<template>
  <Row id="body">
    <!-- 标签-->
    <Col :span="24" class="title">
      <TypographyTitle>{{ $t('nav.name.DeviceManage') }}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <!-- 查询表单-->
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem :label="$t('DeviceManage.table.productId')" name="productId">
                  <Input :placeholder="$t('DeviceManage.validate.productId')" v-model:value="searchForm.productId" allowClear></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('DeviceManage.table.category')" name="categories">
                  <Select
                      v-model:value="categorys"
                      mode="multiple"
                      :placeholder="$t('DeviceManage.validate.category')"
                      style="width: 100%"
                      :options="categorieList"
                      :max-tag-count="5"
                      :filter-option="filterOption"
                      @change="handleChange"
                  ></Select>
                </FormItem>
              </Col>
              <Col :span="6"></Col>
              <Col :span="6"></Col>
            </Row>
          </Form>
          <Row style="margin-bottom: 5px">
            <Col :span="8">
<!--              <Button v-if="checkPermission('ChargerManage.Add')" type="primary" size="default" @click="add()">{{ $t("public.name.add") }}</Button>-->
<!--              <span>&nbsp;</span>-->
<!--              <Button v-if="checkPermission('ChargerManage.Delete')" type="primary" size="default" danger @click="delByIds()">{{ $t("public.name.dels") }}</Button>-->
            </Col>
            <Col :span="8" :offset="8" style="text-align: right;">
              <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
              <span>&nbsp;</span>
              <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <!-- 列表-->
      <Row>
        <Col :span="24">
<!--          :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"-->
          <Table
              :columns="columns"
              :dataSource="list"
              rowKey="id"
              :loading="loading"
              :scroll="{ x: 1500, y: 'calc(100vh - 455px)' }"
              :pagination="false"
              @change="chang_page">

            <template #headerCell="{ column }">
              <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
<!--                <Button v-if="checkPermission('ChargerManage.Add')" type="primary" size="small" shape="circle" @click="add()">-->
<!--                  <PlusOutlined/>-->
<!--                </Button>-->
              </span>
              </template>
            </template>

            <template #bodyCell="{column, record}">
              <template v-if="column.dataIndex === 'id'">
                <a @click="clickId(record.id, record.category)">
                  {{ record.id }}
                </a>
              </template>
              <template v-if="column.dataIndex === 'isOnline'">
                <div>
                  <Tag v-if="record.isOnline" color="#87d068">{{ record.isOnline }}</Tag>
                  <Tag v-else color="#f50">{{ record.isOnline }}</Tag>
                </div>
              </template>
              <template v-if="column.dataIndex === 'action'">
                <div class="pc-display">
                  <Button type="link" size="small" @click="showDetail(record)"> {{ $t("public.name.detail") }} </Button>
<!--                  <Divider type="vertical"/>-->
                  <Button type="link" size="small" v-if="checkPermission('ChargerManage.Edit')" @click="edit(record)">{{ $t("public.name.edit") }}</Button>

<!--                  <Dropdown :trigger="['click']" @click.prevent>-->
<!--                    <a>-->
<!--                      {{ $t("public.name.setting") }}-->
<!--                      <DownOutlined/>-->
<!--                    </a>-->
<!--                    <template #overlay>-->
<!--                      <Menu>-->
<!--                        <MenuItem>-->
<!--                          <Button v-if="checkPermission('ChargerManage.Edit')" type="primary" size="small" @click="edit(record)">{{ $t("public.name.edit") }}</Button>-->
<!--                        </MenuItem>-->
<!--                        <MenuItem>-->
<!--                          <Button v-if="checkPermission('ChargerManage.Delete')" type="primary" danger size="small" @click="del(record)">{{ $t("public.name.del") }}</Button>-->
<!--                        </MenuItem>-->
<!--                        <MenuItem>-->
<!--                          <Button v-if="checkPermission('ChargerManage.StartConn')" type="primary" danger size="small" @click="startConn(record)">{{ $t("chargerManage.name.start") }}</Button>-->
<!--                        </MenuItem>-->
<!--                        <MenuItem>-->
<!--                          <Button v-if="checkPermission('ChargerManage.StopConn')" type="primary" danger size="small" @click="stopConn(record)">{{ $t("chargerManage.name.stop") }}</Button>-->
<!--                        </MenuItem>-->
<!--                      </Menu>-->
<!--                    </template>-->
<!--                  </Dropdown>-->
                </div>
              </template>
            </template>
          </Table>
          <div v-if="showLoadingBut" style="width: 100%; text-align: center;margin-top: 5px"><Button @click="onClickLoading" >loading({{ tablePagination.pageSize }})...</Button></div>
        </Col>
      </Row>
    </Col>
    <DetailModel ref="refDetailModel"></DetailModel>
    <EditModel ref="refEditModel" @updateList="get_list"></EditModel>
  </Row>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "deviceManage",
}
</script>
<script setup>
import {createVNode, ref} from "vue";
import {
  Button,
  Col,
  Dropdown,
  Form,
  FormItem,
  TypographyTitle,
  Input,
  InputNumber,
  Menu,
  MenuItem,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Divider,
} from "ant-design-vue";
import {useI18n} from "vue-i18n";
import {DownOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {ChargerGetListByPage, ChargerDelInfo, ChargerStartConn, ChargerStopConn} from "@/api/charger";
import {DeviceCategories, DevicesList} from "@/api/tuya";
import DetailModel from "./DetailModel";
import EditModel from "./EditModel";
import {useRouter} from "vue-router";

const i18n = useI18n()
let refDetailModel = ref()
let refEditModel = ref()
const router = useRouter()

let showLoadingBut = ref(false)

let categorys = ref([])
let categorieList = ref([])

let startConnButton = ref(false)
let stopConnButton = ref(false)
let searchForm = ref({})
let tablePagination = ref({
  pageSizeOptions: ["10", "20", "50", "100"],
  showSizeChanger: true,
  current: 1,
  pageSize: 20,
  total: 0,
  lastRowKey: undefined
})
let loading = ref(false)
let list = ref([])
let selectedRowKeys = ref([])
const columns = [
  {title: i18n.t("DeviceManage.table.id"), width: 180, ellipsis: true, dataIndex: "id", key: "id"},
  // {title: i18n.t("DeviceManage.table.gatewayId"), width: 180, ellipsis: true, dataIndex: "gatewayId", key: "gatewayId"},
  // {title: i18n.t("DeviceManage.table.nodeId"), width: 180, ellipsis: true, dataIndex: "nodeId", key: "nodeId"},
  // {title: i18n.t("DeviceManage.table.uuid"), width: 180, ellipsis: true, dataIndex: "uuid", key: "uuid"},
  // {title: i18n.t("DeviceManage.table.category"), width: 180, ellipsis: true, dataIndex: "category", key: "category"},
  // {title: i18n.t("DeviceManage.table.categoryName"), width: 180, ellipsis: true, dataIndex: "categoryName", key: "categoryName"},
  {title: i18n.t("DeviceManage.table.name"), width: 180, ellipsis: true, dataIndex: "name", key: "name"},
  {title: i18n.t("DeviceManage.table.productId"), width: 180, ellipsis: true, dataIndex: "productId", key: "productId"},
  {title: i18n.t("DeviceManage.table.productName"), width: 180, ellipsis: true, dataIndex: "productName", key: "productName"},
  // {title: i18n.t("DeviceManage.table.localKey"), width: 180, ellipsis: true, dataIndex: "localKey", key: "localKey"},
  // {title: i18n.t("DeviceManage.table.sub"), width: 180, ellipsis: true, dataIndex: "sub", key: "sub"},
  // {title: i18n.t("DeviceManage.table.assetId"), width: 180, ellipsis: true, dataIndex: "assetId", key: "assetId"},
  // {title: i18n.t("DeviceManage.table.ownerId"), width: 180, ellipsis: true, dataIndex: "ownerId", key: "ownerId"},
  // {title: i18n.t("DeviceManage.table.lon"), width: 180, ellipsis: true, dataIndex: "lon", key: "lon"},
  // {title: i18n.t("DeviceManage.table.lat"), width: 180, ellipsis: true, dataIndex: "lat", key: "lat"},
  {title: i18n.t("DeviceManage.table.model"), width: 180, ellipsis: true, dataIndex: "model", key: "model"},
  // {title: i18n.t("DeviceManage.table.timeZone"), width: 180, ellipsis: true, dataIndex: "timeZone", key: "timeZone"},
  {title: i18n.t("DeviceManage.table.activeTime"), width: 180, ellipsis: true, dataIndex: "activeTime", key: "activeTime"},
  {title: i18n.t("DeviceManage.table.updateTime"), width: 180, ellipsis: true, dataIndex: "updateTime", key: "updateTime"},
  // {title: i18n.t("DeviceManage.table.createTime"), width: 180, ellipsis: true, dataIndex: "createTime", key: "createTime"},
  // {title: i18n.t("DeviceManage.table.icon"), width: 180, ellipsis: true, dataIndex: "icon", key: "icon"},
  {title: i18n.t("DeviceManage.table.isOnline"), width: 90, ellipsis: true, dataIndex: "isOnline", key: "isOnline"},
  {title: i18n.t("DeviceManage.table.ip"), width: 180, ellipsis: true, dataIndex: "ip", key: "ip"},
  {dataIndex: "action", key: "action", fixed: "right", width: 145, ellipsis: true},
]
const selectList = {}

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list(lastRowKey) {
  loading.value = true;
  const api = new DevicesList();
  // 构建查询参数
  const getData = {
    productIds: searchForm.value.productId,
    categories: searchForm.value.categories,
    lastId: lastRowKey,
    pageSize: tablePagination.value.pageSize,
  }
  api.get(getData).then((response) => {
    loading.value = false;
    if (response.data.msg !== undefined) {
      message.error(response.data.msg)
      return
    }
    if (response.data && response.data.length > 0) {
      // console.log("response.data", response.data)
      const lastRowKey = response.data[response.data.length-1].id
      if (getData.lastId !== undefined) {
        if (tablePagination.value.lastRowKey !== lastRowKey) {
          list.value = list.value.concat(response.data)
          showLoadingBut.value = true
        } else {
          showLoadingBut.value = false
        }
      } else {
        list.value = response.data
        showLoadingBut.value = false
      }
      tablePagination.value.lastRowKey = lastRowKey
    } else {
      list.value = []
    }
  });
}

function showDetail(obj) {
  refDetailModel.value?.detailShow(obj);
}

function edit(obj) {
  refEditModel.value?.showEdit(obj);
}

function del(obj) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new ChargerDelInfo();
      let l = []
      l.push(obj.id)
      api.post({ids: l}).then((response) => {
        parseInt(response.data.code) === 0 ? message.success(response.data.msg) : message.error(response.data.msg);
        // 刷新列表
        get_list();
      });
    },
  });
}

function startConn(obj) {
  // 防止重复点击
  if (startConnButton.value) {
    message.error('操作过于频繁');
    return
  }
  startConnButton.value = true;
  loading.value = true
  const api = new ChargerStartConn();
  api.get({identifier: obj.identifier, port: obj.cPort}).then((res) => {
    loading.value = false
    if (0 === parseInt(res.data.code)) {
      get_list();
      message.success(res.data.msg);
    } else {
      get_list();
      message.error(res.data.msg);
    }
  })

  // 延迟执行
  setTimeout(() => {
    startConnButton.value = false
  }, 1000);
}

function stopConn(obj) {
  // 防止重复点击
  if (stopConnButton.value) {
    message.error('操作过于频繁');
    return
  }

  stopConnButton.value = true;
  loading.value = true
  const api = new ChargerStopConn();
  api.get({identifier: obj.identifier, port: obj.cPort}).then((res) => {
    loading.value = false
    if (0 === parseInt(res.data.code)) {
      get_list();
      message.success(res.data.msg);
    } else {
      get_list();
      message.error(res.data.msg);
    }
  })
  // 延迟执行
  setTimeout(() => {
    stopConnButton.value = false
  }, 1000);
}

function onSelectChange(ids) {
  selectedRowKeys.value = ids
}

function delByIds() {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new ChargerDelInfo();
      api.post({ids: selectedRowKeys.value}).then((response) => {
        parseInt(response.data.code) === 0 ? message.success(response.data.msg) : message.error(response.data.msg);
        // 刷新列表
        get_list();
      });
    },
  });
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  categorys.value = []
  tablePagination.value.lastRowKey = undefined
  searchForm.value = {}
  get_list()
}

function clickId(id, category) {
  router.push({
    path: '/DeviceLogs',
    query: {
      id: id,
      category: category,
    },
  });
}

// 加载更多数据
function onClickLoading() {
  get_list(tablePagination.value.lastRowKey)
}

function getDeviceCategorieList() {
  const api = new DeviceCategories();
  api.get().then(response => {
    // console.log("getDeviceCategorieList", response.data)
    if (response.data.msg !== undefined) {
      message.error(response.data.msg)
      return
    }

    let data = []
    response.data.forEach(item => {
      data.push({
        value: item.code,
        label: item.name,
      })
    })
    categorieList.value = data
  })
}

function handleChange(e) {
  categorys.value = categorys.value.slice(0, 5)
  let cs = undefined
  categorys.value?.forEach(item => {
    if (cs === undefined) {
      cs = item
    } else {
      cs = cs + "," + item
    }
  })
  searchForm.value.categories = cs
}

function filterOption(input, option) {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

get_list(undefined)
getDeviceCategorieList()

</script>

<style lang="less">
@import url("style");
</style>
