<template>
  <Modal id="EmployeeDetail" width="60%" :visible="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('employee.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('employee.field.id_emp')">{{data.idEmp}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.id_rol')">{{data.roleName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.account')">{{data.account}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.salt')">{{data.salt}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.last_login_time')">{{data.lastLoginTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.register_time')">{{data.registerTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.login_failure_counter')">{{data.loginFailureCounter}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.email')">{{data.email}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.status')">{{statusList.find(item=>item.value===data.status).label}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.name')">{{data.name}}</DescriptionsItem>
<!--      <DescriptionsItem :label="$t('employee.field.commission_rate')">{{data.commissionRate}}</DescriptionsItem>-->
<!--      <DescriptionsItem :label="$t('employee.field.yedpay_api_key')">{{data.yedpayApiKey}}</DescriptionsItem>-->
<!--      <DescriptionsItem :label="$t('employee.field.yedpay_sign_key')">{{data.yedpaySignKey}}</DescriptionsItem>-->
      <DescriptionsItem :label="$t('employee.field.tenant_key')">{{data.tenantKey}}</DescriptionsItem>
      <DescriptionsItem :label="$t('employee.field.remark')">{{data.remark}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>
<script>
export default {
  name: "EmployeeDetail",
}
</script>
<script setup>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import {ref} from "vue";

const statusList = [{value: 0,label: 'Off'},{value:1,label: 'On'}]

let data = ref({})
let visible = ref(false)

async function detailShow(obj) {
  data.value = JSON.parse(JSON.stringify(obj));
  visible.value = true;
}

defineExpose({
  detailShow
})
</script>